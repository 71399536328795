var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _vm.userRole == "super-admin"
        ? _c(
            "div",
            { staticClass: "col-xs-12 col-sm-4" },
            [
              _c(
                "router-link",
                {
                  attrs: { to: "/" + _vm.userUrl + "/pickls?status=COMPLETED" }
                },
                [
                  _c("Card", {
                    attrs: {
                      icon: "Dashboard/ActivePickl_icon.png",
                      value: _vm.busy
                        ? "Loading..."
                        : _vm.dashboardContent.completed_pickls_count
                        ? _vm.dashboardContent.completed_pickls_count
                        : 0,
                      caption: "Awaiting Approval",
                      color: "#782b54"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.userRole == "super-admin"
        ? _c(
            "div",
            { staticClass: "col-xs-12 col-sm-4" },
            [
              _c(
                "router-link",
                { attrs: { to: "/" + _vm.userUrl + "/picklrs?status=ACTIVE" } },
                [
                  _c("Card", {
                    attrs: {
                      icon: "Dashboard/ActivePicklrs_icon.png",
                      value: _vm.busy
                        ? "Loading..."
                        : _vm.dashboardContent.active_picklrs_count
                        ? _vm.dashboardContent.active_picklrs_count
                        : 0,
                      caption: "PICKL Posse",
                      color: "#cb4f40"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.userRole == "super-admin"
        ? _c(
            "div",
            { staticClass: "col-xs-12 col-sm-4" },
            [
              _c(
                "router-link",
                { attrs: { to: "/" + _vm.userUrl + "/brands" } },
                [
                  _c("Card", {
                    attrs: {
                      icon: "Dashboard/brandsigned_icon.png",
                      value: _vm.busy
                        ? "Loading..."
                        : _vm.dashboardContent.active_brands_count
                        ? _vm.dashboardContent.active_brands_count
                        : 0,
                      caption: "Awesome Brands",
                      color: "#2a8ab7"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "row" }, [
      _vm.userRole == "super-admin"
        ? _c(
            "div",
            { staticClass: "col-xs-12 col-sm-6" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/" + _vm.userUrl + "/brand-subscription-history"
                  }
                },
                [
                  _c("Card", {
                    attrs: {
                      icon: "Dashboard/brandpayment_icon.png",
                      value: _vm.busy
                        ? "Loading..."
                        : _vm.dashboardContent.total_brand_payments
                        ? "$" + _vm.dashboardContent.total_brand_payments
                        : 0,
                      caption: "Gross Revenue",
                      color: "#12db6d"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.userRole == "super-admin"
        ? _c(
            "div",
            { staticClass: "col-xs-12 col-sm-6" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to:
                      "/" + _vm.userUrl + "/picklrs?sortBy=current_credits-DESC"
                  }
                },
                [
                  _c("Card", {
                    attrs: {
                      icon: "Dashboard/OutstandingAmount_icon.png",
                      value: _vm.loadingStripeBalance
                        ? "Loading..."
                        : _vm.stripeBalance
                        ? "$" + _vm.stripeBalance
                        : 0,
                      caption: "Stripe Balance",
                      color: "#d36b00"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _vm.userRole == "super-admin"
      ? _c("h3", { staticClass: "sub-heading" }, [_vm._v("Summary")])
      : _vm._e(),
    _vm.userRole == "super-admin"
      ? _c(
          "div",
          {
            staticClass: "col-xs-12 green-border-box padd-graph white-bg mb20"
          },
          [
            _c("div", { staticClass: "col-xs-12 col-sm-9 col-lg-10 nopadd" }, [
              _c(
                "ul",
                { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
                [
                  _c(
                    "li",
                    { staticClass: "active", attrs: { role: "presentation" } },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "#pickl",
                            "aria-controls": "pickl",
                            role: "tab",
                            "data-toggle": "tab"
                          },
                          on: {
                            click: function($event) {
                              return _vm.switchTab("pickl")
                            }
                          }
                        },
                        [_vm._v("PICKL")]
                      )
                    ]
                  ),
                  _c("li", { attrs: { role: "presentation" } }, [
                    _c(
                      "a",
                      {
                        ref: "amount",
                        attrs: {
                          href: "#pickl",
                          "aria-controls": "pickl",
                          role: "tab",
                          "data-toggle": "tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.switchTab("payment")
                          }
                        }
                      },
                      [_vm._v("AMOUNT")]
                    )
                  ])
                ]
              )
            ]),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-3 col-lg-2 nopadd text-center sort-control"
              },
              [
                _vm._v("\n      View By\n      "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.viewby,
                        expression: "viewby"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.viewby = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { selected: "", value: "1y" } }, [
                      _vm._v("Year")
                    ]),
                    _c("option", { attrs: { selected: "", value: "6m" } }, [
                      _vm._v("6 Months")
                    ]),
                    _c("option", { attrs: { selected: "", value: "3m" } }, [
                      _vm._v("3 Months")
                    ]),
                    _c("option", { attrs: { selected: "", value: "1m" } }, [
                      _vm._v("Month")
                    ])
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "col-xs-12 nopadd" }, [
              _c("div", { staticClass: "tab-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade in active",
                    class: "filter" + _vm.viewby,
                    attrs: { role: "tabpanel", id: "pickl" }
                  },
                  [
                    _c("apexcharts", {
                      attrs: {
                        height: "300",
                        type: "area",
                        options: _vm.chartOptions,
                        series: _vm.chartData
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]
        )
      : _vm._e(),
    _vm.userRole == "super-admin"
      ? _c("h3", { staticClass: "sub-heading" }, [_vm._v("Task Demographics")])
      : _vm._e(),
    _vm.userRole == "super-admin"
      ? _c(
          "div",
          { staticClass: "col-xs-4 green-border-box padd-graph white-bg mb20" },
          [
            _c("h4", [_vm._v("User Task Counts")]),
            _c(
              "ul",
              _vm._l(_vm.usersCountTasks, function(uc) {
                return _c("li", { key: uc.users }, [
                  _c("strong", [_vm._v(_vm._s(uc.users) + " tasks")]),
                  _vm._v(" : " + _vm._s(uc.count) + " users\n      ")
                ])
              }),
              0
            )
          ]
        )
      : _vm._e(),
    _vm.userRole == "super-admin"
      ? _c(
          "div",
          { staticClass: "col-xs-4 green-border-box padd-graph white-bg mb20" },
          [
            _c("h4", [_vm._v("User Task Counts State Wise")]),
            _c(
              "ul",
              _vm._l(_vm.usersCountTasks, function(uc) {
                return _c("li", { key: uc.users }, [
                  _c("strong", [_vm._v(_vm._s(uc.users) + " states")]),
                  _vm._v(" : " + _vm._s(uc.count) + " users\n      ")
                ])
              }),
              0
            )
          ]
        )
      : _vm._e(),
    _vm.userRole == "super-admin"
      ? _c(
          "div",
          { staticClass: "col-xs-4 green-border-box padd-graph white-bg mb20" },
          [
            _c("h4", [_vm._v("Brand Counts For Tasks Per State")]),
            _c(
              "ul",
              _vm._l(_vm.brandsCountTasks, function(bc) {
                return _c("li", { key: bc.states }, [
                  _c("strong", [_vm._v(_vm._s(bc.states) + " states")]),
                  _vm._v(" : " + _vm._s(bc.count) + " brands\n      ")
                ])
              }),
              0
            )
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _vm.userRole == "super-admin"
        ? _c("div", { staticClass: "col-xs-8" }, [
            _c(
              "div",
              { staticClass: "green-border-box padd-graph white-bg mb20" },
              [
                _vm.userRole == "super-admin"
                  ? _c("h3", { staticClass: "sub-heading" }, [
                      _vm._v("Age Demographics")
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-xs-12 nopadd" }, [
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade in active",
                        class: "filter" + _vm.viewby,
                        attrs: { role: "tabpanel", id: "pickl" }
                      },
                      [
                        _c("apexcharts", {
                          attrs: {
                            height: "300",
                            type: "area",
                            options: _vm.chartOptions,
                            series: _vm.ageDemographics
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm.userRole == "super-admin"
        ? _c("div", { staticClass: "col-xs-4" }, [
            _c(
              "div",
              { staticClass: "green-border-box padd-graph white-bg mb20" },
              [
                _vm.userRole == "super-admin"
                  ? _c("h3", { staticClass: "sub-heading" }, [
                      _vm._v("Gender Demographics")
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-xs-12 nopadd" }, [
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade in active",
                        class: "filter" + _vm.viewby,
                        attrs: { role: "tabpanel", id: "pickl" }
                      },
                      [
                        _c(
                          "ul",
                          _vm._l(_vm.genderDemographics, function(
                            gender,
                            index
                          ) {
                            return _c("li", { key: index }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    gender.gender !== null
                                      ? gender.gender
                                      : "Unknown"
                                  )
                                )
                              ]),
                              _vm._v(
                                " : " +
                                  _vm._s(gender.total) +
                                  "\n              "
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  ])
                ]),
                _c("hr"),
                _vm.userRole == "super-admin"
                  ? _c("h3", { staticClass: "sub-heading" }, [
                      _vm._v("Children Demographics")
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-xs-12 nopadd" }, [
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade in active",
                        class: "filter" + _vm.viewby,
                        attrs: { role: "tabpanel", id: "pickl" }
                      },
                      [
                        _c(
                          "ul",
                          _vm._l(_vm.childrenDemographics, function(
                            children,
                            index
                          ) {
                            return _c("li", { key: index }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    children.children !== null
                                      ? children.children
                                      : "Unknown"
                                  )
                                )
                              ]),
                              _vm._v(
                                " : " +
                                  _vm._s(children.total) +
                                  "\n              "
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _vm.userRole == "super-admin"
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 green-border-box padd-graph white-bg mb20"
              },
              [
                _c("div", { staticClass: "col-sm-8" }, [
                  _vm.userRole == "super-admin"
                    ? _c("h3", { staticClass: "sub-heading" }, [
                        _vm._v("Pickl Average Times")
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: " nopadd text-center sort-control",
                      staticStyle: { "margin-top": "20px" }
                    },
                    [
                      _vm._v("\n            View By\n            "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.viewAverageTime,
                              expression: "viewAverageTime"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.viewAverageTime = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            { attrs: { selected: "", value: "all-time" } },
                            [_vm._v("All Time")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "year-2019" } },
                            [_vm._v("2019")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "year-2020" } },
                            [_vm._v("2020")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "year-2021" } },
                            [_vm._v("2021")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "year-2022" } },
                            [_vm._v("2022")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "last-1y" } },
                            [_vm._v("Last Year")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "last-6m" } },
                            [_vm._v("6 Months")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "last-3m" } },
                            [_vm._v("3 Months")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "last-1m" } },
                            [_vm._v("1 Months")]
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _c("table", { staticClass: "table table-striped" }, [
                  _vm._m(0),
                  !_vm.loadingPicklAverageTimes
                    ? _c(
                        "tbody",
                        _vm._l(_vm.averagePicklTimes, function(value, key) {
                          return _c("tr", { key: key }, [
                            _c("td", [_vm._v(_vm._s(key))]),
                            _c("td", [_vm._v(_vm._s(value))])
                          ])
                        }),
                        0
                      )
                    : _c("tbody", [
                        _c("tr", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Loading Data...")
                        ])
                      ])
                ])
              ]
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "col-sm-6" }, [
        _vm.userRole == "super-admin"
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 green-border-box padd-graph white-bg mb20"
              },
              [
                _c("div", { staticClass: "col-sm-8" }, [
                  _vm.userRole == "super-admin"
                    ? _c("h3", { staticClass: "sub-heading" }, [
                        _vm._v("Launched v/s Completed Tasks")
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: " nopadd text-center sort-control",
                      staticStyle: { "margin-top": "20px" }
                    },
                    [
                      _vm._v("\n            View By\n            "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.getComparisionTime,
                              expression: "getComparisionTime"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.getComparisionTime = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            { attrs: { selected: "", value: "all-time" } },
                            [_vm._v("All Time")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "year-2019" } },
                            [_vm._v("2019")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "year-2020" } },
                            [_vm._v("2020")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "year-2021" } },
                            [_vm._v("2021")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "year-2022" } },
                            [_vm._v("2022")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "last-1y" } },
                            [_vm._v("Last Year")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "last-6m" } },
                            [_vm._v("6 Months")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "last-3m" } },
                            [_vm._v("3 Months")]
                          ),
                          _c(
                            "option",
                            { attrs: { selected: "", value: "last-1m" } },
                            [_vm._v("1 Months")]
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("\n                Journey\n              ")]),
        _c("th", [_vm._v("\n                Average Time\n              ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }