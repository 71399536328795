<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-4" v-if="userRole=='super-admin'">
        <router-link :to="'/' + userUrl + '/pickls?status=COMPLETED'">
          <Card
            icon="Dashboard/ActivePickl_icon.png"
            :value="busy ? 'Loading...' : dashboardContent.completed_pickls_count ? dashboardContent.completed_pickls_count:0"
            caption="Awaiting Approval"
            color="#782b54"
          />
        </router-link>
      </div>
      <div class="col-xs-12 col-sm-4" v-if="userRole=='super-admin'">
        <router-link :to="'/' + userUrl + '/picklrs?status=ACTIVE'">
          <Card
            icon="Dashboard/ActivePicklrs_icon.png"
            :value="busy ? 'Loading...' : dashboardContent.active_picklrs_count?dashboardContent.active_picklrs_count:0"
            caption="PICKL Posse"
            color="#cb4f40"
          />
        </router-link>
      </div>
      <div class="col-xs-12 col-sm-4" v-if="userRole=='super-admin'">
        <router-link :to="'/' + userUrl + '/brands'">
          <Card
            icon="Dashboard/brandsigned_icon.png"
            :value="busy ? 'Loading...' : dashboardContent.active_brands_count?dashboardContent.active_brands_count:0"
            caption="Awesome Brands"
            color="#2a8ab7"
          />
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-6" v-if="userRole=='super-admin'">
        <router-link :to="'/' + userUrl + '/brand-subscription-history'">
          <Card
            icon="Dashboard/brandpayment_icon.png"
            :value="busy ? 'Loading...' : dashboardContent.total_brand_payments?'$' + dashboardContent.total_brand_payments:0"
            caption="Gross Revenue"
            color="#12db6d"
          />
        </router-link>
      </div>
      <div class="col-xs-12 col-sm-6" v-if="userRole=='super-admin'">
        <router-link :to="'/' + userUrl + '/picklrs?sortBy=current_credits-DESC'">
          <Card
            icon="Dashboard/OutstandingAmount_icon.png"
            :value="loadingStripeBalance ? 'Loading...' : stripeBalance?'$' + stripeBalance:0"
            caption="Stripe Balance"
            color="#d36b00"
          />
        </router-link>
      </div>
    </div>

    <h3 class="sub-heading" v-if="userRole=='super-admin'">Summary</h3>
    <div class="col-xs-12 green-border-box padd-graph white-bg mb20" v-if="userRole=='super-admin'">
      <div class="col-xs-12 col-sm-9 col-lg-10 nopadd">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a
              href="#pickl"
              aria-controls="pickl"
              role="tab"
              data-toggle="tab"
              @click="switchTab('pickl')"
            >PICKL</a>
          </li>
          <li role="presentation">
            <a
              ref="amount"
              href="#pickl"
              aria-controls="pickl"
              role="tab"
              data-toggle="tab"
              @click="switchTab('payment')"
            >AMOUNT</a>
          </li>
        </ul>
      </div>
      <div class="col-xs-12 col-sm-3 col-lg-2 nopadd text-center sort-control">
        View By
        <select v-model="viewby">
          <option selected value="1y">Year</option>
          <option selected value="6m">6 Months</option>
          <option selected value="3m">3 Months</option>
          <option selected value="1m">Month</option>
        </select>
      </div>
      <div class="col-xs-12 nopadd">
        <div class="tab-content">
          <div
            role="tabpanel"
            class="tab-pane fade in active"
            :class="'filter' + viewby"
            id="pickl"
          >
            <apexcharts height="300" type="area" :options="chartOptions" :series="chartData"/>
          </div>
        </div>
      </div>
    </div>
    <h3 class="sub-heading" v-if="userRole=='super-admin'">Task Demographics</h3>
    <div class="col-xs-4 green-border-box padd-graph white-bg mb20" v-if="userRole=='super-admin'">
      <h4>User Task Counts</h4>
      <ul>
        <li v-for="uc in usersCountTasks" :key="uc.users">
        <strong>{{uc.users}} tasks</strong> : {{uc.count}} users
        </li>
      </ul>
    </div>
    <div class="col-xs-4 green-border-box padd-graph white-bg mb20" v-if="userRole=='super-admin'">
      <h4>User Task Counts State Wise</h4>
      <ul>
        <li v-for="uc in usersCountTasks" :key="uc.users">
        <strong>{{uc.users}} states</strong> : {{uc.count}} users
        </li>
      </ul>
    </div>
    <div class="col-xs-4 green-border-box padd-graph white-bg mb20" v-if="userRole=='super-admin'">
      <h4>Brand Counts For Tasks Per State</h4>
      <ul>
        <li v-for="bc in brandsCountTasks" :key="bc.states">
        <strong>{{bc.states}} states</strong> : {{bc.count}} brands
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-xs-8" v-if="userRole=='super-admin'">
        <div class="green-border-box padd-graph white-bg mb20">
          <h3 class="sub-heading" v-if="userRole=='super-admin'">Age Demographics</h3>
          <div class="col-xs-12 nopadd">
            <div class="tab-content">
              <div
                role="tabpanel"
                class="tab-pane fade in active"
                :class="'filter' + viewby"
                id="pickl">
                <apexcharts height="300" type="area" :options="chartOptions" :series="ageDemographics"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-4" v-if="userRole=='super-admin'">
        <div class="green-border-box padd-graph white-bg mb20">
          <h3 class="sub-heading" v-if="userRole=='super-admin'">Gender Demographics</h3>
          <div class="col-xs-12 nopadd">
            <div class="tab-content">
              <div
                role="tabpanel"
                class="tab-pane fade in active"
                :class="'filter' + viewby"
                id="pickl"
              >
              <ul>
                <li v-for="(gender, index) in genderDemographics" :key="index">
                <strong>{{gender.gender!== null? gender.gender : 'Unknown'}}</strong> : {{gender.total}}
                </li>
              </ul>
              </div>
            </div>
          </div>
          <hr/>
          <h3 class="sub-heading" v-if="userRole=='super-admin'">Children Demographics</h3>
          <div class="col-xs-12 nopadd">
            <div class="tab-content">
              <div
                role="tabpanel"
                class="tab-pane fade in active"
                :class="'filter' + viewby"
                id="pickl"
              >
              <ul>
                <li v-for="(children, index) in childrenDemographics" :key="index">
                <strong>{{children.children!== null? children.children : 'Unknown'}}</strong> : {{children.total}}
                </li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="col-xs-12 green-border-box padd-graph white-bg mb20" v-if="userRole=='super-admin'">
          <div class="col-sm-8">
            <h3 class="sub-heading" v-if="userRole=='super-admin'">Pickl Average Times</h3>
          </div>
          <div class="col-sm-4">
            <div class=" nopadd text-center sort-control" style="margin-top:20px;">
              View By
              <select v-model="viewAverageTime">
                <option selected value="all-time">All Time</option>
                <option selected value="year-2019">2019</option>
                <option selected value="year-2020">2020</option>
                <option selected value="year-2021">2021</option>
                <option selected value="year-2022">2022</option>
                <option selected value="last-1y">Last Year</option>
                <option selected value="last-6m">6 Months</option>
                <option selected value="last-3m">3 Months</option>
                <option selected value="last-1m">1 Months</option>
              </select>
            </div>
          </div>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  Journey
                </th>
                <th>
                  Average Time
                </th>
              </tr>
            </thead>
            <tbody v-if="!loadingPicklAverageTimes">
              <tr v-for="(value, key) in averagePicklTimes" :key="key">
                <td>{{key}}</td>
                <td>{{value}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr style="text-align:center;">Loading Data...</tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="col-xs-12 green-border-box padd-graph white-bg mb20" v-if="userRole=='super-admin'">
          <div class="col-sm-8">
            <h3 class="sub-heading" v-if="userRole=='super-admin'">Launched v/s Completed Tasks</h3>
          </div>
          <div class="col-sm-4">
            <div class=" nopadd text-center sort-control" style="margin-top:20px;">
              View By
              <select v-model="getComparisionTime">
                <option selected value="all-time">All Time</option>
                <option selected value="year-2019">2019</option>
                <option selected value="year-2020">2020</option>
                <option selected value="year-2021">2021</option>
                <option selected value="year-2022">2022</option>
                <option selected value="last-1y">Last Year</option>
                <option selected value="last-6m">6 Months</option>
                <option selected value="last-3m">3 Months</option>
                <option selected value="last-1m">1 Months</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-sm-6">
        <h3 class="sub-heading" v-if="userRole=='super-admin'">Task Launched v/s Tasks Completed</h3>
        <div class="col-xs-12 green-border-box padd-graph white-bg mb20" v-if="userRole=='super-admin'">
          <div id="chart">
            <apexcharts type="bar" height="300" :options="barChartOptions" :series="series"></apexcharts>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import Card from '@/components/common/Card.vue'

export default {
  name: 'Dashboard',
  components: {
    Card,
    apexcharts: VueApexCharts
  },
  data: function () {
    return {
      loadingStripeBalance: true,
      stripeBalance: 0,
      usersCountTasks: [],
      brandsCountTasks: [],
      genderDemographics: [],
      loadingPicklAverageTimes: true,
      averagePicklTimes: [],
      busy: true,
      allChartData: [],
      viewby: '1y',
      viewAverageTime: 'last-1y',
      getComparisionTime: 'last-1y',
      activeTab: 'pickl',
      chartData: [],
      ageDemographics: [],
      paymentChartData: [],
      dashboardContent: {},
      childrenDemographics: {},
      userRole: 'staff',
      userUrl: 'sadmin',
      series: [{
        name: 'Tasks Created',
        data: [250, 200, 240, 245, 200, 210, 280, 300, 150]
      }, {
        name: 'Tasks Completed',
        data: [240, 180, 220, 240, 190, 205, 260, 280, 130]
      }],
      barChartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb']
        },
        yaxis: {
          title: {
            text: 'Tasks'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + 'pickls'
            }
          }
        }
      },
      chartOptions: {
        chart: {
          id: 'pickl-graph',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        xaxis: {
          labels: {
            rotate: 0
          }
        },
        markers: {
          size: 0,
          style: 'hollow',
          strokeColor: '#782b54',
          colors: '#ffffff'
        },
        colors: ['#00E05E', '#EBFCF2'],
        grid: {
          borderColor: '#F2F2F2',
          clipMarkers: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'solid',
          colors: ['#00e05e'],
          opacity: 0.08,
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 1
          }
        },
        stroke: {
          width: 2,
          colors: ['#00E05E']
        },
        tooltip: {
          x: {
            show: false
          },
          custom: function ({ series, seriesIndex, dataPointIndex }) {
            return '<div class="graph-tooltip">' + series[seriesIndex][dataPointIndex] + '</div>'
          },
          marker: {
            show: true
          }
        },
        yaxis: {
          min: 0,
          tickAmount: 4,
          labels: {
            style: {
              cssClass: 'graph-label'
            }
          }
        }
      }
    }
  },
  methods: {
    getSadminDashboard () {
      this.busy = true
      this.$http
        .get('dashboard')
        .then(
          function (response) {
            this.dashboardContent = response.body.data
            this.busy = false
          },
          function (response) {
            this.errorMessage = response.body.message
            this.busy = false
          }
        )
    },
    getSadminReports () {
      this.$http
        .get('dashboard/reports?view=' + this.viewby)
        .then(
          function (response) {
            this.allChartData = response.body
            this.showChartData()
          },
          function (response) {
            this.errorMessage = response.body.message
          }
        )
    },
    getPicklAverageTimes () {
      this.averagePicklTimes = []
      this.loadingPicklAverageTimes = true
      this.$http
        .get('dashboard/pickl-average-times?view=' + this.viewAverageTime)
        .then(
          function (response) {
            this.loadingPicklAverageTimes = false
            this.averagePicklTimes = response.body.pickl_data
          },
          function (response) {
            this.errorMessage = response.body.message
          }
        )
    },

    createPicklComparisionChart () {
      this.$http
        .get('dashboard/pickl-comparision-chart?view=' + this.getComparisionTime)
        .then(
          function (response) {
            this.loadingPicklAverageTimes = false
            this.averagePicklTimes = response.body.pickl_data
          },
          function (response) {
            this.errorMessage = response.body.message
          }
        )
    },

    switchTab (value) {
      this.activeTab = value
      this.showChartData()
    },
    showChartData () {
      if (this.activeTab === 'pickl') {
        this.chartData = [{
          name: 'PICKL',
          data: this.allChartData.pickl_data
        }]
      } else {
        this.chartData = [{
          name: 'PAYMENT',
          data: this.allChartData.payment_data
        }]
      }
    },
    getAgeDemographics () {
      this.$http
        .get('dashboard/age-demographics')
        .then(
          function (response) {
            this.ageDemographics = [{
              name: 'AGE',
              data: response.body.data
            }]
          },
          function (response) {
            this.errorMessage = response.body.message
          }
        )
    },
    getUserTaskCounts () {
      this.$http
        .get('dashboard/user-tasks-count')
        .then(
          function (response) {
            this.usersCountTasks = response.body.data
            console.log(this.usersCountTasks)
          },
          function (response) {
            this.errorMessage = response.body.message
          }
        )
    },
    getBrandTaskCounts () {
      this.$http
        .get('dashboard/brand-tasks-count-with-states')
        .then(
          function (response) {
            this.brandsCountTasks = response.body.data
            console.log(this.brandsCountTasks)
          },
          function (response) {
            this.errorMessage = response.body.message
          }
        )
    },
    getGenderDemographics () {
      this.$http
        .get('dashboard/gender-demographics')
        .then(
          function (response) {
            this.genderDemographics = response.body.data
          },
          function (response) {
            this.errorMessage = response.body.message
          }
        )
    },
    getChildrenDemographics () {
      this.$http
        .get('dashboard/children-demographics')
        .then(
          function (response) {
            this.childrenDemographics = response.body.data
          },
          function (response) {
            this.errorMessage = response.body.message
          }
        )
    },
    getStripeBalance () {
      this.$http.get('dashboard/get-stripe-balance').then((response) => {
        this.stripeBalance = response.data.data.available[0]['amount'] / 100
        this.loadingStripeBalance = false
      })
    }
  },

  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else {
      this.userUrl = 'staff'
    }
    this.getStripeBalance()
    this.getSadminReports()
    this.getSadminDashboard()
    this.getPicklAverageTimes()
    // this.createPicklComparisionChart()
    this.getAgeDemographics()
    this.getGenderDemographics()
    this.getChildrenDemographics()
    this.getBrandTaskCounts()
    this.getUserTaskCounts()
  },
  watch: {
    viewby () {
      this.getSadminReports()
    },
    viewAverageTime () {
      this.getPicklAverageTimes()
    }
  }
}
</script>

<style scoped>
.padd-graph {
  padding: 25px;
}

.tab-content {
  overflow: hidden;
}

>>> .graph-label,
>>> .apexcharts-xaxis tspan {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Google Sans", "Product Sans", sans-serif !important;
}

>>> .graph-label {
  font-size: 15px;
}

>>> .apexcharts-tooltip {
  overflow: visible;
}

>>> .graph-tooltip {
  position: relative;
  background: #782b54;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 5px;
}

/* .graph-tooltip:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(120, 43, 84, 0);
  border-top-color: #782b54;
  border-width: 5px;
  margin-left: -5px;
} */

@media only screen and (max-width: 991px) and (min-width: 576px) {
  >>> .apexcharts-xaxis-label:nth-last-child(even) {
    display: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
  .sort-control {
    margin-top: 15px;
  }

  >>> .filter3m .apexcharts-xaxis-label,
  >>> .filter1m .apexcharts-xaxis-label {
    display: none;
  }

  >>> .filter3m .apexcharts-xaxis-label:first-child,
  >>> .filter3m .apexcharts-xaxis-label:nth-last-child(2),
  >>> .filter1m .apexcharts-xaxis-label:first-child,
  >>> .filter1m .apexcharts-xaxis-label:nth-last-child(2),
  >>> .filter3m .apexcharts-xaxis-label:nth-last-child(5),
  >>> .filter1m .apexcharts-xaxis-label:nth-last-child(4) {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .sort-control {
    margin-top: 15px;
  }

  >>> .apexcharts-xaxis tspan {
    font-size: 12px;
  }

  .padd-graph {
    padding: 15px;
  }

  >>> .filter6m .apexcharts-xaxis-label:nth-last-child(2n) {
    display: none;
  }

  >>> .filter1y .apexcharts-xaxis-label:nth-last-child(4n-1),
  >>> .filter1y .apexcharts-xaxis-label:nth-last-child(4n),
  >>> .filter1y .apexcharts-xaxis-label:nth-last-child(4n-2) {
    display: none;
  }

  >>> .filter3m .apexcharts-xaxis-label,
  >>> .filter1m .apexcharts-xaxis-label {
    display: none;
  }

  >>> .filter3m .apexcharts-xaxis-label:first-child,
  >>> .filter3m .apexcharts-xaxis-label:nth-last-child(2),
  >>> .filter1m .apexcharts-xaxis-label:first-child,
  >>> .filter1m .apexcharts-xaxis-label:nth-last-child(2) {
    display: block;
  }
}
</style>
