<template>
    <div class="card" :style="{backgroundColor: color}">
        <img class="card-image" :src="'/img/' + icon" />
        <div class="card-content">
            <h4 >{{value}}</h4>
            <span v-autosize="{ target: 20, min: 13 }">{{caption}}</span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Card',
  props: ['color', 'icon', 'value', 'caption']
}
</script>

<style scoped>
.card {
  float: left;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  color: white;
  overflow: hidden;
  box-shadow: 0px 3px 11.83px 1.17px rgba(26, 26, 26, 0.21);
  margin-bottom: 20px;
}

.card .card-image {
  float: left;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-right: 10px;
}

.card .card-content {
  float: left;
  width: calc(100% - 75px);
  font-size: 20px;
  height: 60px;
}

.card .card-content h4 {
  float: left;
  width: 100%;
  font-weight: 500;
  font-size: 95%;
  height: 39px;
  line-height: 39px;
  margin: 0;
  vertical-align: top;
  margin-top: -7px;
  margin-bottom: 5px;
}

.card .card-content span {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .card .card-content h4 {
    font-size: 30px;
  }

  .card .card-content {
    font-size: 15px;
    width: calc(100% - 70px);
  }

  .card .card-image {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card {
    padding: 15px 9px;
  }

  .card .card-content {
    font-size: 16px;
    width: calc(100% - 65px);
  }

  .card .card-content h4 {
    font-size: 32px;
  }

  .card .card-image {
    width: 60px;
    height: 60px;
    margin-right: 5px;
  }
}
</style>
