var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card", style: { backgroundColor: _vm.color } },
    [
      _c("img", {
        staticClass: "card-image",
        attrs: { src: "/img/" + _vm.icon }
      }),
      _c("div", { staticClass: "card-content" }, [
        _c("h4", [_vm._v(_vm._s(_vm.value))]),
        _c(
          "span",
          {
            directives: [
              {
                name: "autosize",
                rawName: "v-autosize",
                value: { target: 20, min: 13 },
                expression: "{ target: 20, min: 13 }"
              }
            ]
          },
          [_vm._v(_vm._s(_vm.caption))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }